import React from 'react';
import { useEffect, useState } from 'react';
import './../../style/index.css';
import landing from './../../assets/landingPage.png';
import business from './../../assets/businessCard.png';
import tech from './../../assets/improvement.png';
import webapp from './../../assets/webapp.png';
import mobile from './../../assets/mobileApp.png';
import automation from './../../assets/automation.png';
import vr from './../../assets/vr.png';

const servicesData = [
  {
    seviseTitle: 'Landing pages',
    serviceLogo: landing,
    serviceDesc:
      'Standalone web page, created specifically for a marketing or advertising campaign. ',
  },
  {
    seviseTitle: 'Business card website development',
    serviceLogo: business,
    serviceDesc:
      'Card website is an opportunity to communicate with your target audience. It contains key information about the company and its products, specific facts that describe the benefits of your product or service, contact details and a price list.',
  },
  {
    seviseTitle: 'Technical improvement of the site',
    serviceLogo: tech,
    serviceDesc:
      'Technological Improvement has the general effects of boosting spead rendering, improving design, increasing resource gathering rates, and upgrading the efficiency of web site or app.',
  },
  {
    seviseTitle: 'WEB applications',
    serviceLogo: webapp,
    serviceDesc:
      'A web application is a computer program that utilizes web browsers and web technology to perform tasks.',
  },
  {
    seviseTitle: 'Mobile application development',
    serviceLogo: mobile,
    serviceDesc:
      'Software applications that run on a mobile device, and a typical mobile application utilizes a network connection to work with remote computing resources.',
  },
  {
    seviseTitle: 'Business process automation',
    serviceLogo: automation,
    serviceDesc:
      'Technology-enabled automation of complex business processes. It can streamline a business for simplicity, achieve digital transformation, increase service quality, improve service delivery or contain costs.',
  },
  {
    seviseTitle: 'VR development',
    serviceLogo: vr,
    serviceDesc:
      'Similar to 3D development in that it the spatial design of a large, immersive 3D worlds that theuser will traverse on his own.',
  },
];
const ServicesBlock = (props) => {
  const [clicked, setClicked] = useState(0);
  const [data, setData] = useState({})
  const [fullDesc, setFullDesc] = useState('');
  const [cardName, setCardName] = useState('');
  

  useEffect(() => {
      setData(JSON.parse(JSON.stringify(require('./../../assets/Data/language.json'))));
  }, [])
  
  const onCardClick = (e) => {

      setClicked((prevState) => {
        if(prevState === 1) {
          return 0;
        }
        return 1;
      })
      
    }
    useEffect(() => {
      if(data.ServData && data?.ServData[props.index]) {
        setFullDesc(data?.ServData[props.index].servdescription[props.selectedLang])
        setCardName(data?.ServData[props.index].servTitle[props.selectedLang])
      }
    }, [data, props.selectedLang])
  let cuttedDescription;
  if(data.ServData && data?.ServData[props.index]) {
     cuttedDescription = data?.ServData[props.index].servdescription[props.selectedLang];
    if (cuttedDescription.length > 130) {
      const cuttedText = cuttedDescription.slice(0, 100);
      cuttedDescription = cuttedText.concat('...');
    }
  }
  return (
    <div onClick={onCardClick} className={'technology-block'}>
      <div className={`technology-content ${clicked ? 'rotatedCard' : ''}`}>
        <div className={`technology-content-block ${clicked ? 'rotatedCardBlock' : ''}`}>
          <div className={'technology-logo'}>
            <img className={'technology-logo-img'} src={props.technologyLogo} />
          </div>
          <div className={'slide-block-text'}>
            <h3>{cardName}</h3>
          </div>
          <div className={'technology-desc'}>
            <p className={'technology-desc-text'}>{cuttedDescription}</p>
          </div>
        </div>
        <div className={`back-card ${clicked ? 'rotatedCardBack' : ''}`}>
          <div className={'back-card-header'}>
            <h2>{cardName}</h2>
          </div>
          <div className={'back-card-content'}>
            <p>{fullDesc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ServicerSection = ({selectedLang}) => {
  const [data, setData] = useState({})
  const [sectionName, setSectionName] = useState('');
    useEffect(() => {
      setData(JSON.parse(JSON.stringify(require('./../../assets/Data/language.json'))));
    }, [])


    useEffect(() => {
      if(data.ServData) {
        setSectionName(data?.ServData?.sectionTitle[selectedLang]);

      }
    }, [data, selectedLang])

  return (
    <div className={'technology-section'}>
      <div className={'technology-section-header'}>
        <div className={'technology-header-block'}>{sectionName}</div>
      </div>
      <div className="slider-services">
        <div className="slide-track-ser">
          {servicesData.map((el, index) => {
            return (
              <ServicesBlock
                key={index}
                index={index}
                className="slide"
                selectedLang={selectedLang}
                technologyTitle={el.seviseTitle}
                technologyLogo={el.serviceLogo}
                technologyDesc={el.serviceDesc}
              />
            );
          })}
          {servicesData.map((el, index) => {
            return (
              <ServicesBlock
                key={index}
                className="slide"
                index={index}
                selectedLang={selectedLang}
                technologyTitle={el.seviseTitle}
                technologyLogo={el.serviceLogo}
                technologyDesc={el.serviceDesc}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServicerSection;
