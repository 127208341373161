import React, { useEffect } from 'react';
import { useState } from 'react';
import './style/index.css';
import MainView from './components/MainView/MainView';
import ProjectsSection from './components/ProjectsSection/ProjectsSection';
import MotoSection from './components/MotoSection/MotoSection';
import TechnologySection from './components/TechnologySection/TechnologySection';
import ContactSection from './components/ContactSection/ContactSection';
import ServicerSection from './components/ServicesSection/ServicesSection';
import ProjectDetails from './components/ProjectDetails/ProjectDetails';
import ApproachSection from './components/ApproachSection/ApproachSection';

import Fade from './components/miniComponents/Fade';
import { Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';

// Code-splitting is automated for `routes` directory

const SideContent = ({ selectedLang, setSelectedLang }) => {
  return (
    <>
      <Navbar setSelectedLang={setSelectedLang} selectedLang={selectedLang} />
      <MotoSection selectedLang={selectedLang} />
      <ServicerSection selectedLang={selectedLang} />
      <TechnologySection selectedLang={selectedLang} />
      <ProjectsSection selectedLang={selectedLang} />
      <ApproachSection selectedLang={selectedLang} />
      <ContactSection selectedLang={selectedLang} />
    </>
  );
};

const Main = ({ show, setShow, selectedLang, setSelectedLang }) => {
  return (
    <div>
      <Fade show={show} setShow={setShow}>
        <MainView show={show} setShow={setShow} />
      </Fade>
      {!show ? (
        <SideContent
          setSelectedLang={setSelectedLang}
          selectedLang={selectedLang}
        />
      ) : null}
    </div>
  );
};

function App() {
  const [show, setShow] = useState(true);
  const [selectedLang, setSelectedLang] = useState('EN');

  useEffect(() => {
    const item = localStorage.getItem('language');

    if (item === undefined || item === null) {
      localStorage.setItem('language', 'EN');
      setSelectedLang('EN');
    }
    setSelectedLang(localStorage.getItem('language'));
  }, []);

  return (
    <div id="app">
      <Switch>
        <Route exact path={'/'}>
          <Main
            setSelectedLang={setSelectedLang}
            selectedLang={selectedLang}
            show={show}
            setShow={setShow}
          />
        </Route>
        <Route exact path={'/project/:id'}>
          <Navbar
            selectedLang={selectedLang}
            setSelectedLang={setSelectedLang}
          />
          <ProjectDetails selectedLang={selectedLang} />
        </Route>
        <Route path="*">
          <Main
            setSelectedLang={setSelectedLang}
            selectedLang={selectedLang}
            show={show}
            setShow={setShow}
          />
        </Route>
        <Route path="/project/:id/*">
          <Main
            setSelectedLang={setSelectedLang}
            selectedLang={selectedLang}
            show={show}
            setShow={setShow}
          />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
