import React, { useEffect, useState } from 'react';
import './../../style/index.css';

const ApproachSection = ({ selectedLang }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(
      JSON.parse(JSON.stringify(require('../../assets/Data/language.json')))
    );
  }, []);

  return (
    <div className={'motoSection'}>
      <div className={'motoSection-container'}>
        <div className={'headerTextBlock'}>
          <h1 className={'headerText text-shine-header'}>
            {data?.approachSection?.mainTitle[selectedLang]}
          </h1>
        </div>
        <div className={'underHeaderTextBlock'}>
          <div className={''}>
            <h3 className={'underHeaderText text-shine reverse-shine'}>
              &#8226; {data?.approachSection?.firstTitle[selectedLang]}
            </h3>
            <h3 className={'underHeaderText text-shine reverse-shine'}>
              &#8226; {data?.approachSection?.secondTitle[selectedLang]}
            </h3>
            <h3 className={'underHeaderText text-shine reverse-shine'}>
              &#8226; {data?.approachSection?.thirdTitle[selectedLang]}
            </h3>
            <h3 className={'underHeaderText text-shine reverse-shine'}>
              &#8226; {data?.approachSection?.fourthTitle[selectedLang]}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproachSection;
