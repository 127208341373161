export const projectsData = [
  {
    projectName: "Traction",
    projectNr: 1,
    speed: 0.1,
    description:
      "Traction is the single source of truth for company’s innovation efforts.	It combines the best CRM and Project Management tools to massively boost ability to discover, vet, and adopt new technologies.",
    credit:
      'The Traction platform helps innovation leaders across the world, like "Shell", "Aetna", "Papa Jons", to manage innovation efforts by combining all the knowledge about the technology vendors into one place.',
    type: "web",
    gapDistance: 30,
    link: "https://www.tractiontechnology.com/",
    thumb:
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/traction/thumb.jpg",
    splash:
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/traction/tractionSplash.jpg",
    screenShots: [
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/traction/1.jpg",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/traction/2.png",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/traction/3.jpg",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/traction/5.png",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/traction/6.png",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/traction/7.jpg",
    ],
    screenShotsMobile: [],
  },
  {
    projectName: "Lectio",
    projectNr: 2,
    speed: 0.5,
    description:
      "Lectio is an online learning and teaching platform. Lectio aims to solve several weaknesses of the contemporary educational process, such as the impossibility of visiting the practical classrooms (laboratory rooms) caused by pandemic factors or the poor endowment of school classrooms.",
    credit:
      "Using the Lectio, students or any other persons with access to the application will have the opportunity to improve the theoretical subjects by viewing and manipulating objects of different sizes from chemical particles to planets and stars. Lectio uses webXr technology, which will allow users to access the application and the VR dimension both in a simple web / mobile browser, and with the help of the latest generation VR devices.",
    type: "web",
    gapDistance: 20,
    link: "https://lectio.app/",
    thumb:
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/lectio/thumb.jpg",
    splash: "",
    screenShots: [
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/lectio/1.png",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/lectio/6.png",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/lectio/3.png",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/lectio/4.png",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/lectio/5.png",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/lectio/7.png",
    ],
    screenShotsMobile: [],
  },
  {
    projectName: "Iplan",
    projectNr: 3,
    speed: 0.09,
    type: "web",
    gapDistance: 35,
    link: "http://iplanisrael.com/login",
    description:
      "iplanisrael.com is an Israeli online travel agency for tour planing, lodging, transportation, guides and attractions reservations. It is headquartered in Tel Aviv.",
    credit:
      "This project also includes a back office platform that don’t interface with customers but help your employees manage core functions such as tour planning and financial reporting, booking management and billing. It optimize and automate the processes to boost operational efficiency and travel company profitability.",
    thumb:
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/iplan/thumb.jpg",
    screenShots: [
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/iplan/1.jpg",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/iplan/2.jpg",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/iplan/3.jpg",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/iplan/4.jpg",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/iplan/5.jpg",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/iplan/6.jpg",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/iplan/7.jpg",
    ],
    screenShotsMobile: [],
  },
  {
    projectName: "Fortuna",
    projectNr: 4,
    speed: 0.07,
    type: "mobile",
    gapDistance: 20,
    link: "#",
    description:
      "Fortuna is a mobile app where user can test his luck 100% risk-free. That means free to download and no purchases required to win.",
    credit:
      "Anyone with an account has access to scratch cards and a lot of fun!",
    thumb:
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/scratch/thumbFortuna.jpg",
    screenShots: [
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/scratch/fortunaSplash.png",
    ],
    screenShotsMobile: [
      {
        0: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/scratch/1.jpeg",
        1: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/scratch/2.jpeg",
      },
      {
        0: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/scratch/3.jpeg",
        1: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/scratch/4.jpeg",
      },
      {
        0: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/scratch/5.jpeg",
        1: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/scratch/6.jpeg",
      },
      {
        0: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/scratch/7.jpeg",
        1: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/scratch/8.jpeg",
      },
    ],
  },
  {
    projectName: "Stirbul Design",
    projectNr: 5,
    speed: 0.08,
    type: "web",
    gapDistance: 45,
    link: "https://s3.eu-central-1.amazonaws.com/stirbuldesign.com/index.html",
    description:
      "A site that presents an interior designer and a set of examples of his work, which he use when entering competitions or applying for work.",
    thumb:
      "https://s3.eu-central-1.amazonaws.com/stanislav.portfolio/assets/img/stirbuldesign.jpg",
    splash:
      "https://s3.eu-central-1.amazonaws.com/stirbuldesign.com/assets/img/header-1.jpg",
    screenShots: [
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/stirbul/1.jpg",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/stirbul/2.jpg",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/stirbul/3.jpg",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/stirbul/4.jpg",
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/stirbul/5.jpg",
    ],
    screenShotsMobile: [],
  },
  {
    projectName: "The Crafty Barkeep",
    projectNr: 6,
    speed: 0.1,
    description:
      "Is an American photo and video sharing social networking service. The app allows users to upload media that can be edited with filters and organized by hashtags and locals tagging. Posts can be shared publicly or with pre-approved followers. ",
    credit:
      "Users can browse other users content by tags and locations. Users can like photos and follow other users to add their content to a personal feed.",
    type: "mobile",
    gapDistance: 25,
    link: "https://play.google.com/store/apps/details?id=com.thecraftybar.keep&hl=en_US",
    thumb:
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/crafty/thumb.png",
    screenShots: [
      "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/crafty/splash.png",
    ],
    screenShotsMobile: [
      {
        0: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/crafty/1.png",
        1: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/crafty/2.png",
      },
      {
        0: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/crafty/3.png",
        1: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/crafty/4.png",
      },
      {
        0: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/crafty/5.png",
        1: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/crafty/6.png",
      },
      {
        0: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/crafty/7.png",
        1: "https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/crafty/8.png",
      },
    ],
  },

  // {
  //   projectName: 'RunnerUp',
  //   projectNr: 5,
  //   speed: 0.08,
  //   type: 'mobile',
  //   gapDistance: 45,
  //   splash:
  //     'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/runnerUp/splashRunner.jpg',
  //   description:
  //     'RunnerUp is an American internet service for tracking human exercise which incorporates social network features. It is mostly used for cycling, swiming and running using GPS data.',
  //   credit:
  //     'The app records data for a user`s activities for further competitions between app users.',
  //   thumb:
  //     'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/runnerUp/thumb.jpg',
  //   screenShots: [],
  //   screenShotsMobile: [
  //     {
  //       0: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/runnerUp/processed+(13).jpeg',
  //       1: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/runnerUp/processed+(14).jpeg',
  //     },
  //     {
  //       0: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/runnerUp/processed+(15).jpeg',
  //       1: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/runnerUp/processed+(16).jpeg',
  //     },
  //     {
  //       0: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/runnerUp/processed+(17).jpeg',
  //       1: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/runnerUp/processed+(18).jpeg',
  //     },
  //     {
  //       0: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/runnerUp/processed+(19).jpeg',
  //       1: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/runnerUp/processed+(20).jpeg',
  //     },
  //     {
  //       0: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/runnerUp/processed+(21).jpeg',
  //       1: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/runnerUp/processed+(22).jpeg',
  //     },
  //   ],
  // },

  // {
  //   projectName: 'Klear Aligners',
  //   projectNr: 8,
  //   speed: 0.08,
  //   type: 'mobile',
  //   description:
  //     'Klear Aligners is an app created for a restricted nuumber of experienced orthodontists to help they comunicate regarding patient treatment.',
  //   gapDistance: 34,
  //   link: 'https://play.google.com/store/apps/details?id=com.klearaligners.klear',
  //   thumb:
  //     'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/klear/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA+%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0+2021-08-27+%D0%B2+17.27.47.png',
  //   screenShots: [
  //     'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/klear/splash.jpg',
  //   ],
  //   screenShotsMobile: [
  //     {
  //       0: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/klear/1.png',
  //       1: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/klear/2.jpg',
  //     },
  //     {
  //       0: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/klear/3.png',
  //       1: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/klear/4.jpg',
  //     },
  //     {
  //       0: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/klear/5.jpg',
  //       1: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/klear/6.png',
  //     },
  //     {
  //       0: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/klear/7.png',
  //       1: 'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/klear/8.png',
  //     },
  //   ],
  // },
  // {
  //   projectName: 'Developer Portfolio',
  //   projectNr: 9,
  //   speed: 0.06,
  //   type: 'web',
  //   gapDistance: 25,
  //   link: 'https://s3.eu-central-1.amazonaws.com/stanislav.portfolio/index.html',
  //   description:
  //     'A site that presents a web developer and a set of examples of his work, which he use when entering competitions or applying for work.',
  //   thumb:
  //     'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/portfolio/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA+%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0+2021-08-27+%D0%B2+18.17.00.png',
  //   splash:
  //     'https://s3.eu-central-1.amazonaws.com/stanislav.portfolio/assets/img/cover2.jpg',
  //   screenShots: [
  //     'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/portfolio/1.png',
  //     'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/portfolio/2.png',
  //     'https://s3.eu-central-1.amazonaws.com/brosweb.site/projects_img/portfolio/4.jpg',
  //   ],
  //   screenShotsMobile: [],
  // },
];
