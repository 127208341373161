import React from 'react';
import './../../style/index.css';




const Button = ({comRef, text, height, width, type, inversedB, onClick}) => {
    return(
        <>
            <button onClick={onClick} type={type} ref={comRef} style={{height: height, width: width}} className={inversedB ? 'contact-button-invers' : 'contact-button'}><span>{text}</span></button>
        </>
        
    )
}

export default Button;