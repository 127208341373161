import React, { useEffect, useState } from 'react';
import './../../style/index.css';
import upArrow from './../../assets/upArrow.png';

const MotoSection = ({ selectedLang }) => {
  const [data, setData] = useState({});

  const scrollDown = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  useEffect(() => {
    setData(
      JSON.parse(JSON.stringify(require('./../../assets/Data/language.json')))
    );
  }, []);

  return (
    <div className={'motoSection'}>
      <div className={'motoSection-container'}>
        <div className={'headerTextBlock'}>
          <h1 className={'headerText text-shine-header'}>
            {data?.motoSection?.mainTitle[selectedLang]}
            <br />
            {`& ${data?.motoSection?.mainTitle2[selectedLang]}`}
          </h1>
        </div>
        <div className={'underHeaderTextBlock'}>
          <div className={'underHeaderTextBlockWrapper'}>
            <h3 className={'underHeaderText text-shine reverse-shine'}>
              {data?.motoSection?.underTitle[selectedLang]}
            </h3>
          </div>
        </div>
        <div className={'button-block'}>
          <button
            className={'get-in-touch-button'}
            onClick={() => scrollDown()}
          >
            {' '}
            {data?.motoSection?.buttonTitle[selectedLang]}{' '}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MotoSection;
