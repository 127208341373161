import React from 'react';
import { useState, useEffect } from 'react';
import { projectsData } from '../../assets/Data/projectsData';
import BackgroundPolyline from '../miniComponents/backgroundPolyline';
import './../../style/index.css';
import { useParams, useHistory } from 'react-router-dom';

const ProjectDetails = (props) => {
  const [offsetY, setOffsetY] = useState(0);
  const [projectId, setProjectId] = useState('');
  const [data, setData] = useState({});
  const history = useHistory();

  useEffect(() => {
    setData(
      JSON.parse(JSON.stringify(require('./../../assets/Data/language.json')))
    );
  }, []);
  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
  };

  const progId = useParams().id - 1;
  const checkUrlId = useParams().id;

  useEffect(() => {
    if (!Number(checkUrlId) || progId > 8) {
      history.push('/');
    }
  }, [progId]);

  useEffect(() => {
    setProjectId(Number(props.remaining_path) - 1);
    window.scrollTo(0, 0);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={'project-details-screen'}>
      <div className={'project-main-view'}>
        <div
          style={{
            transform: `translateY(${10}px)`,
            transition: '0.9s',
          }}
          className={'project-details-title'}
        >
          <h3>#{projectsData[progId]?.projectNr}</h3>
          <h1>{projectsData[progId]?.projectName}</h1>
          <h3>
            <a target="_blank" href={projectsData[progId]?.link}>
              {projectsData[progId]?.link && 'link'}
            </a>
          </h3>
        </div>
        <div
          style={{
            transform: `translateY(${10}px)`,
            transition: '0.9s',
          }}
          className={'project-screen-shot'}
        >
          <img
            src={
              projectsData[progId]?.splash ||
              projectsData[progId]?.screenShots[0]
            }
          />
        </div>
      </div>
      <div className={'project-details-info'}>
        <div className={'project-details-description'}>
          <p>{data?.projectDetails?.descriptionTitle[props.selectedLang]}</p>
          <span>
            {data?.projectDetails &&
              data?.projectDetails[progId]?.description1[props.selectedLang]}
          </span>
        </div>
        <div className={'project-details-credit'}>
          <p> </p>
          <span>
            {data?.projectDetails &&
              data?.projectDetails[progId]?.description2[props.selectedLang]}
          </span>
        </div>
      </div>

      <div className={'project-screen-shots'}>
        {projectsData[progId]?.type === 'web'
          ? projectsData[progId]?.screenShots.map((el, index) => {
              return (
                <div
                  key={index}
                  className={`screenShot ${
                    (index + 1) % 2 !== 0 ? 'alignedEnd' : 'alignedStart'
                  }`}
                >
                  <img src={el} />
                </div>
              );
            })
          : projectsData[progId]?.screenShotsMobile.map((el, index, arr) => {
              return (
                <div
                  key={index}
                  className={`mobile-screenshots ${
                    (index + 1) % 2 !== 0 ? 'alignedEnd' : 'alignedStart'
                  }`}
                >
                  {index === 0 || index === arr?.length - 1 ? (
                    <BackgroundPolyline
                      rotatedSvg={(index + 1) % 2 === 0 ? 'rotatedSvg' : ''}
                    />
                  ) : null}
                  <div className={'mobile-screenshots-block'}>
                    <div className={'first-mob'}>
                      <img src={el[0]} />
                    </div>
                    <div className={'second-mob'}>
                      <img src={el[1]} />
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default ProjectDetails;
