import React from 'react';
import './../../style/index.css';



const BackgroundPolyline = ({rotatedSvg}) => {


    return(
    <div className={`background-polygon ${rotatedSvg}`}>
       <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 2450.7 1343.9"  style={{'enable-background':'new 0 0 2450.7 1343.9'}} >

    <g id="base0">
        <polygon className="st0" points="-41.1,715 -169.8,715.6 -101.5,618.1 	"/>
        <polygon className="st1" points="2.6,563.5 -41.1,715 -101.5,618.1 	"/>
        <polygon className="st2" points="-18.6,372.3 -135.4,405.1 2.6,563.5 	"/>
        <polygon className="st3" points="-41.1,715 -103.6,794.2 -38,846.5 	"/>
        <polygon className="st4" points="-169.8,715.6 -103.6,794.2 -41.1,715 	"/>
        <polygon className="st5" points="-136,859 -137.2,807.7 -103.6,794.2 	"/>
        <polygon className="st6" points="-136,859 -103.6,794.2 -38,846.5 	"/>
        <polygon className="st7" points="-153.5,499 -135.4,405.1 2.6,563.5 	"/>
        <polygon className="st8" points="-41.1,715 -169.8,715.6 -101.5,618.1 	"/>
        <polygon className="st8" points="-257.5,671 -236.4,569.2 -101.5,618.1 	"/>
        <polygon className="st8" points="-153.5,499 -101.5,618.1 2.6,563.5 	"/>
        <polygon className="st8" points="-236.4,569.2 -101.5,618.1 -153.5,499 	"/>
        <polygon className="st8" points="-257.5,671 -169.8,715.6 -101.5,618.1 	"/>
        <polygon className="st8" points="2.6,563.5 -41.1,715 -101.5,618.1 	"/>
        <polygon className="st8" points="-18.6,372.3 -135.4,405.1 2.6,563.5 	"/>
        <polygon className="st8" points="-41.1,715 -103.6,794.2 -38,846.5 	"/>
        <polygon className="st8" points="-169.8,715.6 -103.6,794.2 -41.1,715 	"/>
        <polygon className="st8" points="-169.8,715.6 -137.2,807.7 -103.6,794.2 	"/>
        <polygon className="st8" points="-136,859 -137.2,807.7 -103.6,794.2 	"/>
        <polygon className="st9" points="-153.5,499 -101.5,618.1 2.6,563.5 	"/>
        <polygon className="st10" points="-257.5,671 -169.8,715.6 -101.5,618.1 	"/>
        <polygon className="st11" points="-236.4,569.2 -101.5,618.1 -153.5,499 	"/>
        <polygon className="st12" points="-169.8,715.6 -137.2,807.7 -103.6,794.2 	"/>
        <polygon className="st13" points="-257.5,671 -236.4,569.2 -101.5,618.1 	"/>
    </g>
    
    
    <g id="base1">
        <polygon className="st14" points="30.2,984 -37.8,844.3 77.8,774.6 	"/>
        <polygon className="st15" points="50.9,713.1 -41.1,715 1.8,568.4 	"/>
        <polygon className="st16" points="77.8,774.6 144.2,896.7 30.2,984 	"/>
        <polygon className="st15" points="235.7,825.7 77.8,774.6 180.9,746.7 	"/>
        <polygon className="st17" points="77.8,774.6 144.2,896.7 235.7,825.7 	"/>
        <polygon className="st18" points="77.8,774.6 50.9,713.1 180.9,746.7 	"/>
        <polygon className="st17" points="-37.6,843.7 -41.1,715 50.9,713.1 	"/>
        <polygon className="st19" points="97,592.1 50.9,713.1 0,563 	"/>
        <polygon className="st20" points="-37.8,844.3 77.8,774.6 50.9,713.1 	"/>
        <polyline className="st15" points="180.9,746.7 97,592.1 50.9,713.1 	"/>
        <polygon className="st21" points="129.1,515.9 2.6,563.5 -18.6,372.3 	"/>
        <polygon className="st22" points="97,592.1 2.6,563.5 129.1,515.9 	"/>
        <polygon className="st23" points="133.9,390.3 -18.6,372.3 129.1,515.9 	"/>
        <polygon className="st27" points="-38,846.5 -136,859 29.9,985.1 	"/>
        <polygon className="st28" points="129.1,515.9 2.6,563.5 -18.6,372.3 	"/>
        <polygon className="st8" points="97,592.1 2.6,563.5 129.1,515.9 	"/>
        <polygon className="st28" points="133.9,390.3 -18.6,372.3 129.1,515.9 	"/>
        <polygon className="st8" points="-38,846.5 -136,859 29.9,985.1 	"/>
    </g>
    
    
    <g id="base2">
    <polygon className="st24" points="260,503.4 258.2,428.6 129.1,515.9 	"/>
    <polygon className="st25" points="133.9,390.3 284.8,312.6 129.1,515.9 	"/>
        <polygon className="st26" points="258.2,428.6 284.8,312.6 129.1,515.9 	"/>
        <polygon className="st18" points="268,866 85.4,941.7 235.7,825.7 	"/>
        <polygon className="st29" points="265.7,770.1 235.7,825.7 294.4,824.3 	"/>
        <polygon className="st30" points="268,866 294.4,824.3 235.7,825.7 	"/>
        <polygon className="st19" points="265.7,770.1 180.9,746.7 235.7,825.7 	"/>
        <polygon className="st18" points="387.1,605.9 180.9,746.7 265.7,770.1 	"/>
        <polygon className="st16" points="97,592.1 180.9,746.7 387.1,605.9 	"/>
        <polygon className="st31" points="259,504.2 99.1,592.2 387.1,605.9 	"/>
        <polyline className="st32" points="387.1,605.9 414.4,684.9 265.7,770.1 387.1,605.9 	"/>
        <polygon className="st19" points="411.9,576.7 387.1,605.9 257.4,502.9 	"/>
        <polygon className="st33" points="332.3,989.6 243.3,1034.5 299.9,946.3 	"/>
        <polygon className="st34" points="396.4,887 332.3,989.6 299.9,946.3 	"/>
        <polygon className="st29" points="294.4,824.3 299.9,946.3 396.4,887 	"/>
        <polygon className="st34" points="86.1,941.7 299.9,946.3 268,866 	"/>
        <polygon className="st33" points="299.9,946.3 268,866 294.4,824.3 	"/>
        <polygon className="st35" points="258.5,504.1 129.1,515.9 97.7,592 	"/>
        <polygon className="st36" points="396.4,887 295.4,824.3 469.3,825.6 	"/>
        <polygon className="st37" points="265.7,770.1 294.4,824.3 469.3,825.6 	"/>
        <polygon className="st38" points="496.7,906.4 396.4,887 469.3,825.6 	"/>
        <polygon className="st39" points="396.4,887 474,1022.1 332.3,989.6 	"/>
        <polygon className="st8" points="258.5,504.1 129.1,515.9 97.7,592 	"/>
        <polygon className="st28" points="260,503.4 258.2,428.6 129.1,515.9 	"/>
        <polygon className="st28" points="133.9,390.3 284.8,312.6 129.1,515.9 	"/>
        <polygon className="st28" points="258.2,428.6 284.8,312.6 129.1,515.9 	"/>
        <polygon className="st8" points="396.4,887 295.4,824.3 469.3,825.6 	"/>
        <polygon className="st8" points="265.7,770.1 294.4,824.3 469.3,825.6 	"/>
        <polygon className="st8" points="496.7,906.4 396.4,887 469.3,825.6 	"/>
        <polygon className="st8" points="396.4,887 474,1022.1 332.3,989.6 	"/>
    </g>
    
    
    
    <g id="nearright">
        <path className="st40" d="M289.8 766.6L493.5 822l-55-140.6z"/>
        <path className="st41" d="M513.6 915.4l-13.3 117.2L412 904.2z"/>
        <path className="st42" d="M272 503l124.3-57.4L270 428.2z"/>
        <path className="st43" d="M414 447.3L441.2 578l-106.7-51z"/>
        <path className="st44" d="M420.4 685l-2.6-108-24.7 29z"/>
        <path className="st45" d="M305 764.2L509 819.6l-55-140.6z"/>
        <path className="st33" d="M513.6 915.4l-13.3 117.2L412 904.2z"/>
        <path className="st46" d="M399.3 440.4L273 423l26.6-116 99.7 133.4"/>
        <path className="st33" d="M272 503l124.3-57.4L270 428.2z"/>
        <path className="st33" d="M272 503l45 22.4 79.3-79.8"/>
        <path className="st47" d="M272 503l45 22.4 79.3-79.8"/>
        <path className="st33" d="M412 447.6l27.3 130.7-106.7-51z"/>
    </g>
    <g id="top">
        <path className="st48" d="M-22.5 335.5l126.2-101 26.4 119z"/>
        <path className="st49" d="M442.7 423.3l20.5-160.5-120 27z"/>
        <path className="st33" d="M-22.5 335.5l126.2-101 26.4 119z"/>
        <path className="st50" d="M145 321.3l-26.2-119L296 243.6z"/>
        <path className="st51" d="M148.3 311.4l-26.4-119 177 41.3z"/>
        <path className="st52" d="M470 251.7l-122-37L392.3 104 470 251.7"/>
    </g>
    <g id="bottom">
        <path className="st53" d="M23.4 1031.5l213.3 49.4-157-93z"/>
        <path className="st33" d="M27.3 1033l213.3 49.3-157-92.8z"/>
        <path className="st54" d="M481 1064.7l-32.5 95.3-50.8-36zM124.2 1235.6L8.6 1188l69.3 74.3z"/>
        <path className="st51" d="M142 1232.4l-115.6-47.6 69.3 74.3z"/>
        <path className="st55" d="M142 1101.2l60.5 56.5-83.2 59.2z"/>
        <path className="st51" d="M150.5 1086.7l60.6 56.5-83 59.3z"/>
        <path className="st33" d="M483 1054.7l-32.5 95.3-50.8-36z"/>
        <path className="st56" d="M287.8 957.3L74 952.7l157.2 92.8z"/>
    </g>
    <g id="farright">
        <path className="st54" d="M684.3 738l-120.6 32.6L664 787.3z"/>
        <path className="st57" d="M693.5 532.6L869 640.4l-120.6 32.8z"/>
        <path className="st58" d="M481.5 490.4l92.7-58.7-87 192z"/>
        <path className="st59" d="M527.8 314.7l82-73-61.4-87.5z"/>
        <path className="st51" d="M695.7 736L575 769l100.4 16.6zM684.5 551.8l175.5 108-120.6 32.6zM517.3 483l92.8-58.6-87 192z"/>
    </g>



    <g id="base0">
        <polygon className="st0" points="-41.1,715 -169.8,715.6 -101.5,618.1 	"/>
        <polygon className="st1" points="2.6,563.5 -41.1,715 -101.5,618.1 	"/>
        <polygon className="st2" points="-18.6,372.3 -135.4,405.1 2.6,563.5 	"/>
        <polygon className="st3" points="-41.1,715 -103.6,794.2 -38,846.5 	"/>
        <polygon className="st4" points="-169.8,715.6 -103.6,794.2 -41.1,715 	"/>
        <polygon className="st5" points="-136,859 -137.2,807.7 -103.6,794.2 	"/>
        <polygon className="st6" points="-136,859 -103.6,794.2 -38,846.5 	"/>
        <polygon className="st7" points="-153.5,499 -135.4,405.1 2.6,563.5 	"/>
        <polygon className="st8" points="-41.1,715 -169.8,715.6 -101.5,618.1 	"/>
        <polygon className="st8" points="-257.5,671 -236.4,569.2 -101.5,618.1 	"/>
        <polygon className="st8" points="-153.5,499 -101.5,618.1 2.6,563.5 	"/>
        <polygon className="st8" points="-236.4,569.2 -101.5,618.1 -153.5,499 	"/>
        <polygon className="st8" points="-257.5,671 -169.8,715.6 -101.5,618.1 	"/>
        <polygon className="st8" points="2.6,563.5 -41.1,715 -101.5,618.1 	"/>
        <polygon className="st8" points="-18.6,372.3 -135.4,405.1 2.6,563.5 	"/>
        <polygon className="st8" points="-41.1,715 -103.6,794.2 -38,846.5 	"/>
        <polygon className="st8" points="-169.8,715.6 -103.6,794.2 -41.1,715 	"/>
        <polygon className="st8" points="-169.8,715.6 -137.2,807.7 -103.6,794.2 	"/>
        <polygon className="st8" points="-136,859 -137.2,807.7 -103.6,794.2 	"/>
        <polygon className="st9" points="-153.5,499 -101.5,618.1 2.6,563.5 	"/>
        <polygon className="st10" points="-257.5,671 -169.8,715.6 -101.5,618.1 	"/>
        <polygon className="st11" points="-236.4,569.2 -101.5,618.1 -153.5,499 	"/>
        <polygon className="st12" points="-169.8,715.6 -137.2,807.7 -103.6,794.2 	"/>
        <polygon className="st13" points="-257.5,671 -236.4,569.2 -101.5,618.1 	"/>
    </g>
    <g id="base1">
        <polygon className="st14" points="30.2,984 -37.8,844.3 77.8,774.6 	"/>
        <polygon className="st15" points="50.9,713.1 -41.1,715 1.8,568.4 	"/>
        <polygon className="st16" points="77.8,774.6 144.2,896.7 30.2,984 	"/>
        <polygon className="st15" points="235.7,825.7 77.8,774.6 180.9,746.7 	"/>
        <polygon className="st17" points="77.8,774.6 144.2,896.7 235.7,825.7 	"/>
        <polygon className="st18" points="77.8,774.6 50.9,713.1 180.9,746.7 	"/>
        <polygon className="st17" points="-37.6,843.7 -41.1,715 50.9,713.1 	"/>
        <polygon className="st19" points="97,592.1 50.9,713.1 0,563 	"/>
        <polygon className="st20" points="-37.8,844.3 77.8,774.6 50.9,713.1 	"/>
        <polyline className="st15" points="180.9,746.7 97,592.1 50.9,713.1 	"/>
        <polygon className="st21" points="129.1,515.9 2.6,563.5 -18.6,372.3 	"/>
        <polygon className="st22" points="97,592.1 2.6,563.5 129.1,515.9 	"/>
        <polygon className="st23" points="133.9,390.3 -18.6,372.3 129.1,515.9 	"/>
        <polygon className="st24" points="260,503.4 258.2,428.6 129.1,515.9 	"/>
        <polygon className="st25" points="133.9,390.3 284.8,312.6 129.1,515.9 	"/>
        <polygon className="st26" points="258.2,428.6 284.8,312.6 129.1,515.9 	"/>
        <polygon className="st27" points="-38,846.5 -136,859 29.9,985.1 	"/>
        <polygon className="st28" points="129.1,515.9 2.6,563.5 -18.6,372.3 	"/>
        <polygon className="st8" points="97,592.1 2.6,563.5 129.1,515.9 	"/>
        <polygon className="st28" points="133.9,390.3 -18.6,372.3 129.1,515.9 	"/>
        <polygon className="st8" points="-38,846.5 -136,859 29.9,985.1 	"/>
    </g>
    <g id="base2">
        <polygon className="st18" points="268,866 85.4,941.7 235.7,825.7 	"/>
        <polygon className="st29" points="265.7,770.1 235.7,825.7 294.4,824.3 	"/>
        <polygon className="st30" points="268,866 294.4,824.3 235.7,825.7 	"/>
        <polygon className="st19" points="265.7,770.1 180.9,746.7 235.7,825.7 	"/>
        <polygon className="st18" points="387.1,605.9 180.9,746.7 265.7,770.1 	"/>
        <polygon className="st16" points="97,592.1 180.9,746.7 387.1,605.9 	"/>
        <polygon className="st31" points="259,504.2 99.1,592.2 387.1,605.9 	"/>
        <polyline className="st32" points="387.1,605.9 414.4,684.9 265.7,770.1 387.1,605.9 	"/>
        <polygon className="st19" points="411.9,576.7 387.1,605.9 257.4,502.9 	"/>
        <polygon className="st33" points="332.3,989.6 243.3,1034.5 299.9,946.3 	"/>
        <polygon className="st34" points="396.4,887 332.3,989.6 299.9,946.3 	"/>
        <polygon className="st29" points="294.4,824.3 299.9,946.3 396.4,887 	"/>
        <polygon className="st34" points="86.1,941.7 299.9,946.3 268,866 	"/>
        <polygon className="st33" points="299.9,946.3 268,866 294.4,824.3 	"/>
        <polygon className="st35" points="258.5,504.1 129.1,515.9 97.7,592 	"/>
        <polygon className="st36" points="396.4,887 295.4,824.3 469.3,825.6 	"/>
        <polygon className="st37" points="265.7,770.1 294.4,824.3 469.3,825.6 	"/>
        <polygon className="st38" points="496.7,906.4 396.4,887 469.3,825.6 	"/>
        <polygon className="st39" points="396.4,887 474,1022.1 332.3,989.6 	"/>
        <polygon className="st8" points="258.5,504.1 129.1,515.9 97.7,592 	"/>
        <polygon className="st28" points="260,503.4 258.2,428.6 129.1,515.9 	"/>
        <polygon className="st28" points="133.9,390.3 284.8,312.6 129.1,515.9 	"/>
        <polygon className="st28" points="258.2,428.6 284.8,312.6 129.1,515.9 	"/>
        <polygon className="st8" points="396.4,887 295.4,824.3 469.3,825.6 	"/>
        <polygon className="st8" points="265.7,770.1 294.4,824.3 469.3,825.6 	"/>
        <polygon className="st8" points="496.7,906.4 396.4,887 469.3,825.6 	"/>
        <polygon className="st8" points="396.4,887 474,1022.1 332.3,989.6 	"/>
    </g>
    <g id="nearright">
        <polygon className="st40" points="289.8,766.6 493.5,822.1 438.6,681.4 	"/>
        <polygon className="st41" points="513.6,915.4 500.3,1032.6 412,904.2 	"/>
        <polygon className="st42" points="271.9,503 396.3,445.6 270.1,428.2 	"/>
        <polygon className="st43" points="413.9,447.3 441.3,578 334.6,527 	"/>
        <polygon className="st44" points="420.4,685.1 417.8,576.9 393.1,606 	"/>
        <polygon className="st45" points="305.1,764.2 508.8,819.6 453.9,679 	"/>
        <polygon className="st8" points="513.6,915.4 500.3,1032.6 412,904.2 	"/>
        <polyline className="st46" points="399.3,440.4 273,422.9 299.6,307 399.3,440.4 	"/>
        <polygon className="st8" points="271.9,503 396.3,445.6 270.1,428.2 	"/>
        <polyline className="st8" points="271.9,503 317,525.4 396.3,445.6 	"/>
        <polyline className="st47" points="271.9,503 317,525.4 396.3,445.6 	"/>
        <polygon className="st8" points="411.9,447.6 439.3,578.3 332.6,527.4 	"/>
    </g>
    <g id="top">
        <polygon className="st48" points="-22.5,335.5 103.7,234.5 130.1,353.5 	"/>
        <polygon className="st49" points="442.7,423.3 463.2,262.8 343.1,289.9 	"/>
        <polygon className="st8" points="-22.5,335.5 103.7,234.5 130.1,353.5 	"/>
        <polygon className="st50" points="145.1,321.3 118.8,202.3 296,243.6 	"/>
        <polygon className="st51" points="148.3,311.4 121.9,192.3 299.1,233.7 	"/>
        <polyline className="st52" points="469.9,251.7 347.9,214.6 392.4,104.2 469.9,251.7 	"/>
    </g>
    <g id="bottom">
        <polygon className="st53" points="23.4,1031.5 236.7,1080.9 79.6,988.1 	"/>
        <polygon className="st8" points="27.3,1033 240.6,1082.3 83.5,989.5 	"/>
        <polygon className="st54" points="480.9,1064.7 448.5,1160 397.7,1123.9 	"/>
        <polygon className="st54" points="124.2,1235.6 8.6,1188 77.9,1262.3 	"/>
        <polygon className="st51" points="142,1232.4 26.4,1184.8 95.7,1259.1 	"/>
        <polygon className="st55" points="142,1101.2 202.5,1157.7 119.3,1216.9 	"/>
        <polygon className="st51" points="150.5,1086.7 211.1,1143.2 127.9,1202.5 	"/>
        <polygon className="st8" points="482.9,1054.7 450.5,1150 399.7,1113.9 	"/>
        <polygon className="st56" points="287.8,957.3 74.1,952.7 231.2,1045.5 	"/>
    </g>
    <g id="farright">
        <polygon className="st54" points="684.3,737.9 563.7,770.6 664,787.3 	"/>
        <polygon className="st57" points="693.5,532.6 869,640.4 748.4,673.2 	"/>
        <polygon className="st58" points="481.5,490.4 574.2,431.7 487.1,623.8 	"/>
        <polygon className="st59" points="527.8,314.7 609.7,241.6 548.4,154.2 	"/>
        <polygon className="st51" points="695.7,736.1 575.1,768.8 675.4,785.4 	"/>
        <polygon className="st51" points="684.5,551.8 860,659.7 739.4,692.4 	"/>
        <polygon className="st51" points="517.3,483.1 610.1,424.4 522.9,616.5 	"/>
    </g>
</svg>
    </div>
);
}

export default BackgroundPolyline;