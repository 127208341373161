import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './../../style/index.css';

const ProjectComponent = (props) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isMobileDevice, setIsmobileDevice] = useState(false);
  const [offsetY, setOffsetY] = useState(0);
  const { gapDistance, speed, projectName, projectNr, thumb } = props.project;

  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
  };
  const handleWindowWidthChange = () => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleWindowWidthChange);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  }, []);

  useEffect(() => {
    if (windowSize <= 609) {
      setIsmobileDevice(true);
    } else setIsmobileDevice(false);
  }, [windowSize]);
  return (
    <div
      style={{
        transform: `translateY(${10}px)`,
        transition: '1.5s',
        marginTop: `${!isMobileDevice ? gapDistance : ''}px`,
      }}
      className={'projectCom'}
    >
      <Link className={'project-link'} to={`/project/${projectNr}`}>
        <div
          style={{
            transform: `translateY(${10}px)`,
            transition: '1.5s',
          }}
          className={'projectNumber'}
        >
          <p className={'projectNumberText'}>#{projectNr}</p>
        </div>

        <div className={'projectMiniView'}>
          <img className={'projectScreenShot'} src={thumb} />
        </div>
        <div className={'projectName'}>
          <p className={'projectNameText'}>{projectName}</p>
        </div>
      </Link>
    </div>
  );
};

export default ProjectComponent;
