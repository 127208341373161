import React from 'react';
import { useState, useEffect, createRef} from 'react';
import './../../style/index.css';


const Fade = ({ show, setShow, children}) => {

    const [render, setRender] = useState(show);
    useEffect(() => {
        if(show) setRender(true);
    }, [show]);


    const onAnimationEnd = () => {
        if(!show) setRender(false);
    }

    return(
        render && (
            <div
            style={{
                animation: `${show ? 'fadeIn' : 'fadeOut'} 1s`,
                position: 'relative',
                width: '100%'
            }}
            onAnimationEnd={onAnimationEnd}
            className={'fade-container'}
        >
            {children}
        </div>
        )
    )
}


export default Fade;