import React, { Component } from 'react';
import { particlesSettings } from './../../assets/Data/particlesSettings';
import * as THREE from 'three';
import './../../style/index.css';
import Particles from 'react-particles-js';
import downArrow from './../../assets/down.png';

class MainView extends Component {
  constructor(props) {
    super(props);
    this.sectionRef = React.createRef();
    this.goDownRef = React.createRef();
  }

  handleScroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  swapContainer(event) {
    this.props.setShow(false);
  }

  componentDidMount() {
    // this.window?.particlesJS(particlesSettings, 'particles-js');
    if(window.innerWidth < 746) {
      window.addEventListener('scroll', (event) => {
      if (event.deltaY < 0) {
        return null;
      } else if (this.props.show) {
        fadeInSphere();
        this.swapContainer(event);
      }
    })
    }
    window.addEventListener('wheel', (event) => {
      if (event.deltaY < 0) {
        return null;
      } else if (this.props.show) {
        fadeInSphere();
        this.swapContainer(event);
      }
    });
    let renderer, scene, camera, circle, skelet;
    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(
      window.devicePixelRatio ? window.devicePixelRatio : 1
    );
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.autoClear = false;
    renderer.setClearColor(0x000000, 0.0);
    this.mount.appendChild(renderer.domElement);
    scene = new THREE.Scene();

    camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      1,
      1000
    );
    camera.position.z = 400;
    scene.add(camera);

    circle = new THREE.Object3D();
    skelet = new THREE.Object3D();

    scene.add(circle);
    scene.add(skelet);
    onWindowResize(this.windowHeight);

    let geom = new THREE.IcosahedronGeometry(7, 1);
    let geom2 = new THREE.IcosahedronGeometry(15, 1);

    let mat = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      flatShading: THREE.FlatShading,
    });

    let mat2 = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      wireframe: true,
      side: THREE.DoubleSide,
    });

    let planet = new THREE.Mesh(geom, mat);
    planet.scale.x = planet.scale.y = planet.scale.z = 16;
    circle.add(planet);

    let planet2 = new THREE.Mesh(geom2, mat2);
    planet2.scale.x = planet2.scale.y = planet2.scale.z = 10;
    skelet.add(planet2);

    let ambientLight = new THREE.AmbientLight(0x999999);
    scene.add(ambientLight);

    let lights = [];
    lights[0] = new THREE.DirectionalLight(0xdef2f1, 0.4);
    lights[0].position.set(1, 0, 0);
    lights[1] = new THREE.DirectionalLight(0x11e8bb, 1);
    lights[1].position.set(0.75, 1, 0.5);
    lights[2] = new THREE.DirectionalLight(0x0f5c5a, -0.1);
    lights[2].position.set(-0.75, -1, 0.5);
    scene.add(lights[0]);
    scene.add(lights[1]);
    scene.add(lights[2]);

    window.addEventListener(
      'resize',
      () => {
        onWindowResize(this.windowHeight);
      },
      false
    );

    function onWindowResize(windowHeight) {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      if (window.innerWidth <= 625 && window.innerWidth > 510) {
        camera.position.z = 500;
      } else if (window.innerWidth > 625) {
        camera.position.z = 400;
      } else if (window.innerWidth <= 510 && window.innerWidth > 395) {
        camera.position.z = 600;
      } else if (window.innerWidth <= 395 && window.innerWidth > 325) {
        camera.position.z = 700;
      } else if (window.innerWidth <= 325 && window.innerWidth > 395) {
        camera.position.z = 720;
      }
    }
    let hovering = null
    let bruh = 0
    function incrementOnHover() {
      if(bruh <= 20 && hovering === 'hover') {
      circle.scale.z += 0.006;
      circle.scale.x += 0.006;
      circle.scale.y += 0.006;
      skelet.scale.z += 0.006;
      skelet.scale.x += 0.006;
      skelet.scale.y += 0.006;
      bruh += 1
      renderer.clear();

      renderer.render(scene, camera);
      requestAnimationFrame(incrementOnHover);
      } else {
        bruh = 0;
        hovering = null;
        return 0;
      }
    }
    let bruh2 = 0;
    let hovering2 = null
    function decrementOnHover() {
      if(bruh2 <= 20 && hovering2 === 'unhover' ) {
      circle.scale.z -= 0.006;
      circle.scale.x -= 0.006;
      circle.scale.y -= 0.006;
      skelet.scale.z -= 0.006;
      skelet.scale.x -= 0.006;
      skelet.scale.y -= 0.006;
      bruh2 += 1;
      renderer.clear();

      renderer.render(scene, camera);
      requestAnimationFrame(decrementOnHover);
      }else {
        bruh2 = 0;
        hovering2 = null;
        return 0;
      }
     
    }

    function fadeInSphere() {
      requestAnimationFrame(fadeInSphere);
      circle.rotation.x -= 0.01;
      circle.rotation.y -= 0.01;
      skelet.rotation.x -= 0.01;
      skelet.rotation.y += 0.01;
      skelet.position.z += 10;
      circle.position.z += 10;
      renderer.clear();

      renderer.render(scene, camera);
    }

    this.sectionRef.current.addEventListener('mouseenter', () => {
      hovering = 'hover';
      incrementOnHover()
    });
    this.sectionRef.current.addEventListener('mouseleave', () => {
      hovering2 = 'unhover';
      decrementOnHover();
    });
    this.sectionRef.current.addEventListener('click', () => {
      fadeInSphere();
      this.props.setShow(false);
    });

    function animate() {
      requestAnimationFrame(animate);

      circle.rotation.x -= 0.002;
      circle.rotation.y -= 0.003;
      skelet.rotation.x -= 0.001;
      skelet.rotation.y += 0.002;
      renderer.clear();

      renderer.render(scene, camera);
    }
    animate();
    this.goDownRef.current.addEventListener('click', fadeInSphere);
    const check = () => {
      if (!this.props.show) {
        fadeInSphere();
      }
    };
    check();
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.swapContainer);
  }

  render() {
    return (
      <div id="particles-js" className="mainViewContainer">
        <div className={'cicleBlock'} ref={(ref) => (this.mount = ref)} />
        <Particles width={'100%'} height={'100vh'} params={particlesSettings} />
        {this.props.show ? (
          <div ref={this.sectionRef} className="mainStage">
            <h1 className="mainStageText">BrosWeb</h1>
            <hr className={'mainStageLine'} />
            <h3 className={'mainStageSideText'}>Clever solutions</h3>
          </div>
        ) : null}
        <div
          ref={this.goDownRef}
          onClick={() => this.props.setShow(false)}
          className={'down-arrow-block'}
        >
          {this.props.show ? (
            <div className={'box bounce-6'}>
              <img src={downArrow} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default MainView;
