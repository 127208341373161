import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import logo from "./../../assets/logo.png"


const Navbar = ({selectedLang, setSelectedLang}) => {

  const onSelectChange = (e) => {
    setSelectedLang(e.target.options[e.target.selectedIndex].text);
    localStorage.setItem("language", `${e.target.options[e.target.selectedIndex].text}`);
  }

  return(
     <div className={'navbar-block'} >
                <Link className={'header-container'} to="/">
                  <img src={logo} className={'navbar-logo'} />
                  <h2>BROSWEB</h2>
                </Link>
              <div className={'ln-dropdown-select'} >
                <select  value={selectedLang} onChange={(e) => onSelectChange(e)} className={'dropdown-select'} >
                  <option value="EN">EN</option>
                  <option value="RU">RU</option>
                </select>
              </div>
    </div>
  )
}


export default Navbar;