import React from 'react';
import { useEffect, useState } from 'react';
import Button from '../miniComponents/Button';
import './../../style/index.css';
import goBack from './../../assets/cancel.png';
import upArrow from './../../assets/upArrow.png';
import faceBookIcon from './../../assets/facebookIcon.png';

const ContactSection = ({ selectedLang }) => {
  const buttonRef = React.createRef(null);
  const formRef = React.createRef(null);
  const goBackRef = React.createRef(null);

  const [data, setData] = useState({});
  useEffect(() => {
    setData(
      JSON.parse(JSON.stringify(require('./../../assets/Data/language.json')))
    );
  }, []);

  const openForm = () => {
    if (buttonRef && buttonRef.current) {
      formRef?.current?.classList.add('form-section-open');
    }
  };
  const closeForm = () => {
    formRef?.current?.classList.remove('form-section-open');
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={'contact-section'}>
      <div className={'footer-content'}>
        <div className={'contactSectionHeader'}>
          <h1>{data?.footerSection?.contactHeader[selectedLang]}</h1>
        </div>
        <div className={'button-block'}>
          <Button
            comRef={buttonRef}
            onClick={() => openForm()}
            text={data?.footerSection?.contactButton[selectedLang]}
            width={'70%'}
            height={'170px'}
          />
          <p>
            Email:{' '}
            <a href={'mailto:broswebmw@gmail.com'}>broswebmw@gmail.com</a>
          </p>

          <p>
            {data?.footerSection?.faceBookFollowText?.[selectedLang]}{' '}
            <a
              className={'facebook-link'}
              rel="tooltip"
              title="Like us on Facebook"
              data-placement="bottom"
              href={'https://www.facebook.com/people/BrosWeb/100063569321747/'}
              target={'_blank'}
            >
              <img src={faceBookIcon} />
            </a>
          </p>
          <p>
            {data?.footerSection?.mapText?.[selectedLang]}
            <a
              rel="noreferrer"
              target={'_blank'}
              href={'https://goo.gl/maps/1FvsYLLVBG2DeFZz6'}
            >
              [ Google MAP ]
            </a>{' '}
          </p>
        </div>
      </div>
      <div className={'up-arrow-container'}>
        <div className={'up-arrow-block'}>
          <img onClick={() => scrollUp()} src={upArrow} />
        </div>
      </div>
      <div ref={formRef} className={'form-section'}>
        <div className={'go-back-button'}>
          <img
            onClick={() => {
              closeForm();
            }}
            ref={goBackRef}
            src={goBack}
          />
        </div>
        <div className={'form-container'}>
          <form
            action="https://formspree.io/broswebmw@gmail.com"
            method={'POST'}
          >
            <input
              type={'email'}
              name={'email'}
              placeholder={
                data?.footerSection?.formFields?.mailInput[selectedLang]
              }
              required
            />
            <input
              type={'text'}
              name={'userName'}
              placeholder={
                data?.footerSection?.formFields?.nameInput[selectedLang]
              }
              required
            />
            <textarea
              type={'text'}
              name={'description'}
              maxLength={200}
              placeholder={
                data?.footerSection?.formFields?.messageField[selectedLang]
              }
            />
            <input type="hidden" name="_next" value="http://www.brosweb.site" />
            <Button
              type={'submit'}
              text={data?.footerSection?.sendButton[selectedLang]}
              height={'100px'}
              inversedB={true}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
