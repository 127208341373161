import React, {useEffect, useState} from 'react';
import ProjectComponent from './ProjectComponent';
import { projectsData } from "../../assets/Data/projectsData";
import './../../style/index.css';

const ProjectsSection = ({selectedLang}) => {
    const [projectData, setProjectData] = useState({})
    useEffect(() => {
        setProjectData(JSON.parse(JSON.stringify(require('./../../assets/Data/language.json'))))
    }, [])
    return(
        <div className={'projectsSectionBlock'} >
            <div className={'projectSectionHeader'} >
                <h1>{projectData?.projectSection?.projectSectionTitle[selectedLang]}</h1>
                <hr className={'headerUnderline'} />
            </div>
            <div className={'projectsContainer'}>

                {
                    projectsData.map((item, index) => {
                        return <ProjectComponent 
                            key={index} 
                            project={item} 
                            />
                    })
                }
            </div>
        </div>
    )
}

export default ProjectsSection;